/* eslint-disable @typescript-eslint/no-explicit-any */
import { Pagination } from './common'
import { GuildMembersResponse } from './guild'
import { HorseInGate } from './horse'
import { WeatherCurrentModel } from './weather'

export enum RaceStatus {
  OPEN = 'OPEN',
  SCHEDULING = 'SCHEDULING',
  LIVE = 'LIVE',
  FINISHED = 'FINISHED',
  RESULT = 'RESULT',
  WAITING = 'WAITING',
  CLOSED = 'CLOSED',
  CANCEL = 'CANCEL'
}

export enum RaceClass {
  Begin = 'Begin',
  Iron = 'Iron',
  Copper = 'Copper',
  Silver = 'Silver',
  Gold = 'Gold',
  Diamond = 'Diamond',
  Ruby = 'Ruby'
}

export enum RaceClassNumber {
  Class1 = 'Class 1',
  Class2 = 'Class 2',
  Class3 = 'Class 3',
  Class4 = 'Class 4',
  Class5 = 'Class 5',
  Class6 = 'Class 6',
  Class7 = 'Class 7',
  ClassFreeStyle = 'FreeStyle'
}

export type RaceClassRankingFilter = {
  name: RaceClassNumber
  isActive: boolean
}

export type ClassRankingFilters = RaceClassRankingFilter[]

export enum DistanceOption {
  DISTANCE_1000 = '1000 m',
  DISTANCE_1200 = '1200 m',
  DISTANCE_1400 = '1400 m',
  DISTANCE_1600 = '1600 m',
  DISTANCE_1800 = '1800 m',
  DISTANCE_2000 = '2000 m',
  DISTANCE_2200 = '2200 m',
  DISTANCE_2400 = '2400 m',
  DISTANCE_2600 = '2600 m',
  DISTANCE_2800 = '2800 m',
  DISTANCE_3000 = '3000 m',
  DISTANCE_3200 = '3200 m',
}

export type DistanceOptionFilter = {
  name: DistanceOption
  isActive: boolean
}

export type DistanceFilters = DistanceOptionFilter[]

export enum RegionOption {
  TOKYO = 'Tokyo',
  SEOUL = 'Seoul'
}

export type RegionOptionFilter = {
  name: RegionOption | 'All'
  isActive: boolean
}
export type RegionFilters = RegionOptionFilter[]

export const defaultRegionFilters: any = [
  {
    name: 'All',
    key: 'all',
    isActive: true
  },
  {
    name: RegionOption.TOKYO,
    key: RegionOption.TOKYO,
    isActive: false
  },
  {
    name: RegionOption.SEOUL,
    key: RegionOption.SEOUL,
    isActive: false
  }
]

export enum RaceFieldType {
  Turf = 'TURF',
  Dirt = 'DIRT'
}

export type RaceTypeRankingFilter = {
  name: RaceFieldType
  isActive: boolean
}

export type TypeRankingFilters = RaceTypeRankingFilter[]

export interface GetRaceListParams {
  limit: number
  page: number
  raceClass?: RaceClassNumber
  fieldType?: RaceFieldType
  region?: string
  sort?: string[]
  freeRace?: boolean
  myHorse?: boolean
  search?: string
  status?: RaceStatus
}
export interface GetRaceCancelParams {
  limit: number
  page: number
}
export interface RaceWaiting {
  id: number
  startAt: string
}

export interface GetRaceListPopupParams {
  limit: number
  page: number
  raceClass?: RaceClassNumber
  fieldType?: RaceFieldType
  sort?: string
  freeRace?: boolean
  myHorse?: boolean
  search?: string
  startAt: string
  endAt: string
  startInstance: string | null
  endInstance: string | null
  region?: string | null
}

interface Course {
  city: string
  country: string
  id: number
  name: string
}

export interface RecordRace {
  count_down: number
  course: Course
  distance: number
  end_at: number | null
  entry_fee: number
  field_type: string
  id: number
  image: string
  name: string
  public_at: number
  race_type: string
  racing_class_name: RaceClass
  registered: number
  start_at: number
  status: RaceStatus
  total_prize: number
  real_start_at: number
  protected_race: boolean
  race_no: string
}

export interface GetRaceListResponse extends Pagination {
  records: RecordRace[]
}

export interface Gate {
  experience_received: number
  gate: string
  horse: HorseInGate | null
  race_position: string
}

export interface RaceResultContract {
  contract_url: string
  detail_url: string
}

export interface Race {
  count_down: string
  count_down_cancel: number
  current_time: number
  course: Course
  distance: {
    distance: number
    id: number
  }
  end_at: number
  close_at: number
  entry_fee: number
  field_type: {
    id: number
    type: string
  }
  gates: Gate[]
  had_joined: boolean
  id: number
  name: string
  public_at: string
  race_prizes: {
    position: number
    prize: number
  }[]
  race_type: string
  racing_class: {
    id: number
    name: string
  }
  registered: number
  start_at: string
  status: string
  total_prize: number
  real_start_at: number
  protected_race: boolean
  race_blockchain_url: string
  contract_url: string
  detail_url: string
  weather?: WeatherCurrentModel
  category?: string
  legacy_data?: boolean
  weapon_items_equipped?: weaponsitem[],
  jackpot?: boolean,
  jackpot_detail?: any,
  is_new_record?: boolean,
  new_record_detail?: any
  current_record_holder?: any
}

export interface JoinRaceBody {
  gate: number
  horse_id: number
  password?: string | null
}

export interface enterRacePassword {
  password: string | null
}

export interface EnterRaceError {
  code: 400
  message: string
}

export interface EnterRaceErrorDupplicate {
  code: 429
  message: string
}

export interface Step {
  a: number
  ask: number[]
  d: number
  dask: number[]
  phase: string
  s: number
  sps: number
  t: number
  v: number
}

export interface StepHorse {
  id: number
  highlight: boolean
  animation: string
  avatar: string
  blood_line: {
    code: string
    color: string
    color_code: string
    colorCode: string
    id: number
    name: string
    type: string
  }
  bloodline: {
    code: string
    color: string
    color_code: string
    colorCode: string
    id: number
    name: string
    type: string
  }  
  bloodLine: {
    code: string
    color: string
    color_code: string
    colorCode: string
    id: number
    name: string
    type: string
  }
  weapon_items_equipped?: weaponsitem[],
  horse_weapon_items?: weaponsitem[],
  characteristic: string
  characteristic_code: string
  encode_steps: [[[number]]]
  gender: string
  horse_abilities: {
    abilities_code: string
    level: number
    name: string
  }[]
  horse_skills: {
    description: string
    name: string
    skill_code: string
  }[]
  horse_stats: {
    current_value: number
    stats_type: string
  }[]
  level: number
  model: string
  name: string
  run_type: string
  sound: string
  steps: Step[]
  subAvatar: string
  run_type_code: string
  runTypeCode: string
  hair_color: string
  sub_avatar?: string
  stepsByTime: {
    [key: number]: Step
  }
  finishStepTime?: number
  owner?: { id: number; name?: string }
  guild: GuildMembersResponse
  horseEquipment: {
    horseWeaponItems: weaponsitem
  }
  hairColor: string
  region?: string
  is_new_record?: boolean
}
export interface weaponsitem {
  active: boolean
  body_part: string
  color: string
  description: string
  effect: {
    effect: [
      {
        master_effect_type: string
        unit: string
        value: number
      }
    ]
  }
  id: number
  img: string
  item_set_id: number
  item_token: number
  name: string
  own_status: string
  rarity_type: string
  status: string
}
export interface StepHorseList {
  horses: StepHorse[]
}

export interface RankHorse {
  horseIndex: number
  step: Step
  reachFinishLine: boolean
  lastStep?: Step
  preLastStep?: Step
}

export type RaceResult = RecordRace & {
  race_data: StepHorseList
  data_url: string
}

export interface CancelCountDown {
  code: number
  data: string
}

export interface GetRevealPositionsResponse {
  race_positions: [
    {
      gate: string
      horse_id: number
      race_position: number
    }
  ]
  winner: {
    gate: string
    horse_id: number
    race_position: number
  }
  reward_winner: {
    is_jackpot: boolean
    is_new_record: boolean
  }
}
